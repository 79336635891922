import { Box, Container, Flex, Headline, Text } from "@boligportal/juice";
import { Footer } from "components/footer";
import { Header } from "components/header";

export const ErrorPageApp = ({
  code,
  message,
}: {
  code: number;
  message: string;
}) => (
  <>
    <Header />
    <Box flexGrow>
      <Container>
        <Flex
          justify="center"
          align="center"
          column
          mt={5}
        >
          <Headline as="h1">{code}</Headline>
          <Text mb={4}>{message}</Text>
        </Flex>
      </Container>
    </Box>
    <Footer />
  </>
);
